@import '../../../styles/variables';

.Content {
  border-radius: 10px;
  overflow: hidden;
  background-color: white;
  display: flex;
  flex-direction: column;
  padding-bottom: 3em;
  box-shadow: $shadow;

  &--loading {
    justify-content: center;
    padding-bottom: 0;
  }

  &__image {
    height: 10em;

    img,
    video {
      height: 100%;
      width: 100%;
      object-fit: cover;
      max-width: none;
    }
  }

  &__title {
    display: flex;
    flex-direction: column;
    padding: 0 3em;
    margin-bottom: 1em;
    margin-top: 2em;

    &Points {
      background-color: $secondary-color;
      color: white;
      padding: 0.125em 0.5em;
      border-radius: 3px;
      margin-bottom: 0.5em;
      align-self: flex-start;
    }

    &Text {
      font-size: 1.5em;
    }
  }

  &__answers {
    padding: 0 3em;
    display: grid;
    grid-template-columns: repeat(2, 1fr);
    grid-gap: 1em;

    .Answer {
      cursor: pointer;

      &--reorderable {
        cursor: grab;
      }

      &--disabled {
        cursor: default;
      }
    }
  }

  @media (max-width: 1000px) {
    &__answers {
      grid-template-columns: repeat(1, 1fr);
    }
  }
}
