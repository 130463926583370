@import './variables';

html,
body {
  height: 100%;
  padding: 0;
  margin: 0;
  color: $primary-color;
}

* {
  box-sizing: border-box;
}

body {
  font-family: 'Lato', Arial, Helvetica, sans-serif;
}

i {
  font-family: 'Material Icons';
  text-decoration: none;
  font-style: normal;
  user-select: none;
}

#app {
  height: 100%;
}
