@import '../../styles/variables';

.ProgressBar {
  border-radius: 10px;
  background-color: $light-gray;
  height: 15px;
  overflow: hidden;

  &__fill {
    height: 100%;
  }
}
