@import '../../../styles/variables';

.ImportContentModal {
  padding: 3em 5em;
  display: flex;
  flex-direction: column;

  &__title {
    font-size: 2em;
    margin-bottom: 1em;
  }

  .MuiButtonBase-root {
    margin-top: 1em;
  }

  &__recap {
    font-size: 0.8em;
    color: $dark-gray;
  }
}
