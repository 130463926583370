.container {
    width: auto;
    height: auto;
}

#container__button {
    position: relative;
    width: 12px;
    height: 12px;

    &:before,
    &:after {
        content: "";
        position: absolute;
        background-color: rgb(8, 8, 8);
        transition: transform 0.25s ease-out;
    }

    /* Vertical line */
    &:before {
        top: 0;
        left: 57%;
        width: 2px;
        height: 100%;
        margin-left: -2px;
    }

    /* horizontal line */
    &:after {
        top: 55%;
        left: 0;
        width: 100%;
        height: 2px;
        margin-top: -2px;
    }

    &:hover {
        cursor: pointer;
    }

}

input[type=checkbox]:checked~.to-be-changed {
    &:before {
        transform: rotate(90deg);
    }

    &:after {
        transform: rotate(180deg);
    }
}