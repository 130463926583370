.Modal {
  background-color: rgba(0, 0, 0, 0.75);
  padding: 5em 3em 3em 3em;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: fixed;
  z-index: 1201;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;

  &__body {
    background-color: white;
    position: relative;
    border-radius: 10px;
    min-height: 20em;
    min-width: 20em;
    max-width: 75em;
  }
}
