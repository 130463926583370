.questions {
    display: flex;
    flex-direction: column;
    background-color: white;
    box-shadow: 0px 2.5px 5px rgba(0, 0, 0, 0.1);
    border-radius: 5px;
    padding: 0.5em;
    height: auto;
}

.questions .section {
    display: flex;
    flex-direction: column;
    height: auto;
    width: 70%;
}

.section .header {
    display: flex;
    height: 2em;
    align-items: center;
}

.section .body {
    height: auto;
    padding-left: 1em;
    width: 100%;
}

.header .--button {
    display: flex;
    justify-content: center;
    align-items: center;
}

.header label {
    display: flex;
    justify-content: center;
    align-items: center;
    font-size: auto;
    font-weight: bold;
}

.header .resetButton {
    display: flex;
    justify-content: center;
    align-items: center;
    height: 70%;
    width: 3em;
    background-color: #dedede;
    margin-left: 3em;
    font-weight: bold;

    &:hover {
        cursor: pointer;
    }

    &:active {
        background-color: #a1a1a1;
    }
}

.resetButton__title {
    font-weight: bold;
}