@import '../../styles/variables';

.Session {
  background: url('../../../assets/images/background.png') no-repeat center;
  background-size: cover;
  min-height: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;
  position: relative;

  video {
    max-width: 50em;
    align-self: center;
  }

  &__background {
    &Overlay {
      position: absolute;
      z-index: 0;
      background-color: white;
      opacity: 50%;
      height: 100%;
      width: 100%;
    }
  }

  &__body {
    display: flex;
    flex-direction: column;
    position: relative;
    padding: 1.5em 3em;
    max-width: 75em;
    width: 100%;
  }

  &__header {
    display: flex;
    align-items: center;
    justify-content: space-between;
    margin-bottom: 1.5em;

    &Progress {
      display: flex;
      flex-direction: column;
      align-items: center;
      justify-content: center;
      width: 1em;
      flex-grow: 1;
      margin: 0 5em;
      font-weight: bold;
      text-transform: uppercase;

      .ProgressBar {
        width: 100%;
        margin-bottom: 1em;
        background-color: white;
      }
    }
  }

  &__correction {
    margin-top: 2em;
  }

  &__buttons {
    display: flex;
    justify-content: center;
    margin-top: 2em;
  }

  @media (max-width: 1000px) {
    background: #dedede;

    &__header {
      .Button {
        display: none;
      }

      &Progress {
        align-items: flex-start;
        margin: 0;
      }
    }

    &__body {
      flex-grow: 1;
      padding: 1.5em;
      padding-bottom: 6em;
    }

    &__content {
      flex-grow: 1;
    }

    &__buttons {
      position: fixed;
      bottom: 0;
      left: 0;
      padding: 0;
      width: 100%;
      height: 3em;
      font-size: 1.5em;

      .Button {
        width: 100%;
        height: 100%;
        border-radius: 0;
      }
    }
  }
}
