$primary-color: #092530;
$secondary-color: #62a5e2;
$accent-color: #ed931b;
$dark-gray: #7f7f7f;
$light-gray: #e3e3e1;
$light-gray-background: #efefef;

$error-color: #d53a3a;
$success-color: #23d0ad;

$header-height: 5em;
$shadow: 0px 2px 20px rgba(0, 0, 0, 0.2);

@mixin custom-scrollbar {
  &::-webkit-scrollbar {
    background-color: transparent;

    &:vertical {
      width: 0.5em;
    }

    &:horizontal {
      height: 0.5em;
    }

    &-corner {
      background-color: transparent;
    }

    &-thumb {
      border-radius: 1em;
      background-color: $light-gray;
    }
  }
}

@mixin pell {
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  flex-grow: 1;
  display: flex;
  flex-direction: column;
  background-color: white;
  margin-bottom: 1em;
  padding: 1em;
  outline: none;

  .Editor {
    &__languages {
      img {
        width: 2em;
        height: 2em;
        object-fit: cover ;
      }
    }
  }

  .pell-actionbar {
    border: none;
    display: flex;
  }

  .pell-content {
    outline: none;
    flex-grow: 1;
    margin-top: 1em;
    overflow: auto;
  }

  .pell-button {
    padding: 0.75em 1em;
    height: auto;
    width: auto;
    min-width: 3.5em;
    display: flex;
    justify-content: center;
    border: none;
    box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
    margin-right: 0.75em;
    cursor: pointer;
    outline: none;

    .material-icons {
      font-size: 1.5em;
    }

    &:last-child {
      margin-right: 0;
    }
  }

  // .Editor__vars {
  //   position: relative;

  //   .Editor__variables {
  //     position: absolute;
  //     top: 2em;
  //     text-align: left;
  //     background-color: white;
  //     box-shadow: 0 1px 3px rgba(0, 0, 0, 0.12), 0 1px 2px rgba(0, 0, 0, 0.24);
  //     width: 15em;

  //     > div {
  //       padding: 1em;

  //       &:hover {
  //         background-color: lighten($theme, 5%);
  //         color: white;
  //       }
  //     }
  //   }
  // }
}

.pell-editor {
  @include pell;

  height: 20em;
}
