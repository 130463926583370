.Contents {
  padding: 3em 5em;
}

.buttonAvailable {
  margin-right: '1em';
  font-size: '0.8em';
  text-transform: 'none';
  font-family: Arial, Helvetica, sans-serif;

  &:hover {
    cursor: not-allowed;
  }

  &:last-child {
    margin-right: 0;
  }
}