.title {
    width: 100%;
    height: 2em;
    display: flex;
    justify-content: space-between;
    margin-bottom: 1em;
}

.title__logo {
    height: 100%;
    width: 25%;
    font-size: 1.5em;
}

.title__color {
    height: 100%;
    width: 30%;
    font-size: 1.5em;
}

.licenseCustom {
    display: flex;
    justify-content: space-between;
    width: 100%;
    height: 9em;
}

.colorPicker {
    height: 100%;
    width: 35%;
    display: flex;
    align-items: flex-start;
    justify-content: space-between;
}

.underColrPicker {
    display: flex;
    justify-content: space-between;
    width: 100%;
}

.underColorPickerInput {
    display: flex;
    justify-content: space-between;
    width: 35%;
}

.underColorPickerInput__hexa {
    margin-top: 2em;
    width: 8.5em
}